import React from 'react'
import { Heading, Box } from '@chakra-ui/react';
import { Link } from 'gatsby'
import Layout from '../components/Layout';
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import pageTheme from '../gatsby-plugin-chakra-ui/theme'
import SEO from '../components/SEO'

const theme = extendTheme({ ...pageTheme })

const Page404 = () => (
    <ChakraProvider theme={theme}>
        <Layout className="testingsteve" heroContent={{
            heroSlides: [{
                heroSlideIllustrationSelect: 'careers',
                heroSlideContentH1: 'Oops, that\'s a <strong>404!</strong>',
                heroSlideContentH4: 'We can’t seem to find the page you’re looking for.',
                heroSlideLink: null,
                secondaryButton: null,
                teritaryButton: null,
            }],
        }}>
            <SEO title="404" />
            <Box mt="300px" mb="150px" className="container" maxWidth="1024px">
                <Heading as="h4" textAlign="center">
                    Try refining your search, or use the navigation above to locate the post, page or event.
                </Heading>
                <Heading as="h4" textAlign="center">
                    Can't find what you're looking for? Try our <Link to="/support">Support Center</Link>
                </Heading>
            </Box>
        </Layout>
    </ChakraProvider>
)

export default Page404